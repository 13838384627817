export const PaymentStatus = {
  REQUIRES_PAYMENT_METHOD: 0,
  REQUIRES_CONFIRMATION: 1,
  REQUIRES_ACTIONS: 2,
  PROCESSING: 3,
  REQUIRES_CAPTURE: 4,
  CANCELED: 5,
  SUCCEEDED: 6,
  SCHEDULED: 7,
  CREATED: 8,
  FAILED: 9,
  QUOTED: 10,
};

export const PaymentStatusLabel = {
  [PaymentStatus.REQUIRES_PAYMENT_METHOD]: 'Requires payment method',
  [PaymentStatus.REQUIRES_CONFIRMATION]: 'Requires confirmation',
  [PaymentStatus.REQUIRES_ACTIONS]: 'Requires actions',
  [PaymentStatus.PROCESSING]: 'Processing',
  [PaymentStatus.REQUIRES_CAPTURE]: 'Requires capture',
  [PaymentStatus.CANCELED]: 'Canceled',
  [PaymentStatus.SUCCEEDED]: 'Succeeded',
  [PaymentStatus.SCHEDULED]: 'Scheduled',
  [PaymentStatus.CREATED]: 'Created',
  [PaymentStatus.FAILED]: 'Failed',
  [PaymentStatus.QUOTED]: 'Quoted',
};
